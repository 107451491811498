<template>
  <component :is="to ? NuxtLink : 'div'" :to="to" class="lesson-card" :class="{ clickable: to }">
    <div class="info-container">
      <img v-if="lesson.avatar_url" :src="lesson.avatar_url" alt="Аватар" class="avatar">
      <AvatarPlaceholder v-else medium :data="lesson" class="avatar" />
      <div class="info">
        <ClientOnly>
          <div class="time">{{ time }}</div>
        </ClientOnly>
        <div class="credentials">
          <div class="name">{{ fullName }}</div>
          <div class="role">{{ lesson.skills !== undefined ? 'Ментор' : 'Менти' }}</div>
        </div>
      </div>
    </div>
  </component>
</template>

<script setup>
import AvatarPlaceholder from '@/components/common/AvatarPlaceholder'
import { formatTime } from '~/utils/date'
import NuxtLink from "#app/components/nuxt-link";

const props = defineProps({
  lesson: {
    type: Object,
    required: true
  },
  to: Object
})

const fullName = computed(() => `${props.lesson.first_name} ${props.lesson.last_name}`)
const time = computed(() => `${formatTime(props.lesson.book.datetime_start)} - ${formatTime(props.lesson.book.datetime_end)}`)
</script>

<style scoped lang="scss">
.lesson-card {
  border: 1px solid var(--color-separator);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background: none;
  & .info-container {
    display: flex;
    align-items: center;
    & .avatar {
      flex-shrink: 0;
      width: 99px;
      height: 99px;
      border-radius: 10px;
      object-fit: cover;
      margin-right: 20px;
    }
    & .info {
      & .time {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
      }
      & .credentials {
        margin-top: 5px;
        & .name {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
        & .role {
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: var(--color-elements-secondary);
        }
      }
    }
  }
  &.clickable {
    user-select: none;
    cursor: pointer;
    transition: .1s border-color;
    &:hover {
      opacity: 1;
      border-color: var(--color-primary);
    }
  }
}
</style>
