<template>
  <component :is="to ? NuxtLink : 'div'" :to="to" class="mentoring-simple-card" :class="{ clickable: to }">
    <img v-if="data.avatar_url" :src="data.avatar_url" alt="Аватар" class="avatar">
    <AvatarPlaceholder v-else :data="data" small class="avatar" />
    <div class="info">
      <div class="name">{{ fullName }}</div>
      <div class="role">{{ role }}</div>
    </div>
  </component>
</template>

<script setup>
import AvatarPlaceholder from '@/components/common/AvatarPlaceholder'
import NuxtLink from '#app/components/nuxt-link'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  to: Object
})

const fullName = computed(() => `${props.data.first_name} ${props.data.last_name}`)
const role = computed(() => props.data.skills !== undefined ? 'Ментор' : 'Менти')
</script>

<style scoped lang="scss">
.mentoring-simple-card {
  padding: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-separator);
  background: none;
  & .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    flex-shrink: 0;
  }
  & .info {
    & .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    & .role {
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--color-elements-secondary);
    }
  }
  &.clickable {
    cursor: pointer;
    user-select: none;
    & .name {
      transition: .1s color;
    }
    &:hover {
      opacity: 1;
      & .name {
        color: var(--color-primary);
      }
    }
  }
}
</style>
