<template>
  <div class="nearest-lesson">
    <div class="title">Ближайшее занятие</div>
    <div class="info">
      <div class="mentor">
        <div class="mentor-name">{{ fullName }}</div>
        <div class="mentor-role">{{ data.skills !== undefined ? 'Ментор' : 'Менти' }}</div>
      </div>
      <ClientOnly>
        <div class="date" :title="title">
          <b>{{ day }}</b>
          <span>{{ month }}</span>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup>
import months from '@/data/months'
import { formatTime } from '~/utils/date'

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const fullName = computed(() => `${props.data.first_name} ${props.data.last_name}`)
const day = computed(() => props.data.book.datetime_start.getDate())
const month = computed(() => months.genitive[props.data.book.datetime_start.getMonth()])
const title = computed(() => `${formatTime(props.data.book.datetime_start)} - ${formatTime(props.data.book.datetime_end)}`)
</script>

<style scoped lang="scss">
.nearest-lesson {
  padding: 12px 24px;
  width: 242px;
  box-sizing: content-box;
  @media (max-width: 680px) {
    padding-left: 0;
  }
  & .title {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-elements-secondary);
    margin-bottom: 3px;
  }
  & .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .mentor {
      &-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      &-role {
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--color-elements-secondary);
      }
    }
    & .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: var(--color-elements-secondary);
      text-transform: lowercase;
      & b {
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        color: var(--color-primary);
        margin-bottom: -6px;
      }
    }
  }
}
</style>
