<template>
  <main>
    <div class="horiz-container" :class="{ pending }">
      <div class="top">
        <h1 class="page-title">Календарь</h1>
        <NearestLesson v-if="nearestLesson" :data="nearestLesson" />
      </div>
      <div class="content default-grid">
        <div class="mentors-container">
          <div class="title">{{ user.is_mentor ? 'Менти' : 'Менторы' }}</div>
          <div class="mentor-list">
            <MentoringSimpleCard
              v-for="item in items"
              :key="item.id"
              :data="item"
              :to="{ name: 'mentor-lessons', params: { id: item.id } }"
            />
          </div>
        </div>
        <div class="calendar-container">
          <Calendar :lessons="lessons" @select-day="selectDay" :selected-day="selectedDay" />
        </div>
      </div>
    </div>
    <Teleport to="body">
      <transition name="sidebar-fluid" mode="out-in">
        <MentoringLessonsSidebar
          v-if="selectedDay !== null"
          @close="selectedDay = null"
          :day="selectedDay"
          :lessons="selectedDayLessons"
          :key="selectedDay?.dateString"
        />
      </transition>
    </Teleport>
  </main>
</template>

<script setup>
import NearestLesson from '@/components/calendar/NearestLesson'
import Calendar from '@/components/calendar/Calendar'
import { getMyMenties, getMyMentors } from '@/api/mentoring'
import MentoringSimpleCard from '@/components/mentoring/MentoringSimpleCard'
import { getNearestLesson, LessonsNormalize } from '~/utils/calendar'
import MentoringLessonsSidebar from '@/components/mentoring/MentoringLessonsSidebar'
import useDaySelection from '~/composables/calendar/daySelection'

useHead({
  title: 'Календарь'
})

const user = inject('user')

const { data: items, pending } = await useAsyncData(() => (user.value.is_mentor ? getMyMenties : getMyMentors)())

const nearestLesson = computed(() => items.value ? getNearestLesson(items.value) : null)
const lessons = computed(() => LessonsNormalize.fromBookings(items.value))

const { selectedDay, selectedDayLessons, selectDay } = useDaySelection(lessons)
</script>

<style scoped lang="scss">
.page-title {
  margin-bottom: 20px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 12px;
  margin-bottom: 51px;
  border-bottom: 1px solid var(--color-separator);
  @media (max-width: 680px) {
    flex-direction: column;
  }
}

.content {
  & .mentors-container {
    grid-column: span 3;
    @media (max-width: 1280px) {
      grid-column: span 12;
      grid-row: 2;
      margin-top: 40px;
    }
    & .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
  & .calendar-container {
    grid-column: span 9;
    @media (max-width: 1280px) {
      grid-column: span 12;
    }
  }
}
</style>
